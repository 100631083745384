import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { convertNumberToMoney, priceData, safeSumTwoIntegers } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { capitalizeWord, richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  OrderPanel,
  EditListingActionBar,
  BookingsCalendar,
} from '../../components';

import { sendEnquiry, setInitialValues, fetchTransactionLineItems } from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe/SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionAdditionalInfo from './SectionAdditionalInfo';
import SectionReviews from './SectionReviews';
import SectionMoreListingsMaybe from './SectionMoreListingsMaybe';
import { createListingTitle, getListingDescription } from '../../util/listingHelpers';
import { getFirstLocationPart } from '../../util/maps';
import routeConfiguration from '../../routing/routeConfiguration';
import defaultConfig from '../../config/configDefault';
import EnquiryForm from './InquiryForm/InquiryForm';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import css from './ListingPage.module.css';
import { GoogleTagManagerHandler } from '../../analytics/handlers';
import { estimatePayinTotal } from '../../components/OrderPanel/BookingTimeForm/EstimatedBreakdownMaybe';
import { getNormalizedFee } from '../../util/sessions.js';
import { CUSTOMER_FEE_PERCENTAGE } from '../../transactions/transactionsUtil.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const getPriceWithFee = (price, feeAmount) => {
  if (!price) return;

  return convertNumberToMoney(price.amount + feeAmount, price.currency);
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  componentDidMount() {
    const { params, getListing, currentUser } = this.props;

    const listingId = new UUID(params.id);

    try {
      const currentListing = ensureListing(getListing(listingId));

      const authorAvailable = currentListing && currentListing.author;
      const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
      const isOwnListing =
        userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

      if (isOwnListing) return;

      GoogleTagManagerHandler.trackListingView(currentListing);
    } catch (error) {
      console.warn('Failed tracking listing view');
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { dates, adults = 1, children = 0, ...restOfValues } = values;

    const { startDate: bookingStart, endDate: bookingEnd } = dates;

    const bookingData = {
      quantity: 1,
      adults,
      children,
      seats: safeSumTwoIntegers(adults, children),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        GoogleTagManagerHandler.trackHostContact(ensureListing(getListing(listingId)), txId);

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      listingsInRegion,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      reviewsMeta,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      sessions,
      fetchSessionsInProgress,
      fetchSessionsError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const monthlyTimeSlots = {
      timeSlots: sessions,
      fetchTimeSlotsError: fetchSessionsError,
      fetchTimeSlotsInProgress: fetchSessionsInProgress,
    };

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    if (rawParams.id && !rawParams.slug) {
      // Add slug to params
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      geolocation = null,
      price: listingPrice = null,
      publicData,
    } = currentListing.attributes;

    const {
      description,
      descriptionsByDay,
      meetingLocation,
      city,
      discipline,
      ability,
      type,
      duration,
      groupType,
      languages,
      cancellationPolicy,
    } = publicData || {};

    const createdTitle = createListingTitle(intl, {
      discipline,
      ability,
      type,
      duration,
      groupType,
    });

    const title = createdTitle ? capitalizeWord(createdTitle.trim()) : '';

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const metaDescription = getListingDescription(description);

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
            <p className={css.errorText}>
              <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
            </p>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPage.loadingListingMessage" />
            </p>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const { uuid: authorId } = ensuredAuthor.id;
    const { averageRating, ratingCount = 0 } = ensuredAuthor.attributes.profile.metadata || {};

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const isCurrentUserAuthor = currentUser?.id?.uuid === ensuredAuthor.id?.uuid;

    const buyerFee = isCurrentUserAuthor
      ? CUSTOMER_FEE_PERCENTAGE
      : currentUser?.attributes?.profile?.metadata?.customerFee;

    const feeAmount =
      listingPrice?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

    const price = getPriceWithFee(listingPrice, feeAmount);

    const { formattedPrice } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
        try {
          const payinTotal = estimatePayinTotal(lineItems);
          GoogleTagManagerHandler.trackBookingInitiated(
            currentListing,
            payinTotal.amount,
            payinTotal.currency
          );
        } catch (err) {
          console.warn('Failed tracking BookingInitialized');
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = defaultConfig.marketplaceName;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, city, siteTitle }
    );

    const actionBar = isOwnListing && <EditListingActionBar listing={currentListing} />;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        openGraphType="website"
        description={metaDescription}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: metaDescription,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn topbar={topbar} footer={<FooterContainer />}>
          <div className={css.contentContainer}>
            {actionBar}
            {isOwnListing && (
              <div className={css.bookingsCalendarWrapper}>
                <BookingsCalendar listingId={currentListing.id} className={css.bookingsCalendar} />
              </div>
            )}
            <div className={css.headerWrapper}>
              <SectionHeading
                listing={currentListing}
                richTitle={richTitle}
                averageRating={averageRating}
                ratingCount={ratingCount}
              />
              <SectionImages
                title={title}
                listing={currentListing}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
            <div className={css.bodyContent}>
              <div className={css.mainContent}>
                <SectionAvatar user={currentAuthor} params={params} />

                <SectionDetailsMaybe details={currentListing.attributes} />
                {description && typeof description !== 'string' && (
                  <SectionDescriptionMaybe
                    description={description}
                    descriptionsByDay={descriptionsByDay}
                    selectedLanguages={languages}
                  />
                )}
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                  mapsConfig={defaultConfig.maps}
                />
                <SectionFeaturesMaybe publicData={publicData} />

                <SectionReviews
                  authorId={authorId}
                  reviews={reviews}
                  reviewsMeta={reviewsMeta}
                  fetchReviewsError={fetchReviewsError}
                  averageRating={averageRating}
                  ratingCount={ratingCount}
                />
              </div>

              <div className={css.bookingPanel}>
                <OrderPanel
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  onContactUser={this.onContactUser}
                  isListingPendingApproval={!isApproved}
                />

                <SectionAdditionalInfo
                  className={css.additionalInfo}
                  cancellationPolicy={cancellationPolicy}
                />
              </div>
            </div>

            <SectionMoreListingsMaybe
              location={city || getFirstLocationPart(meetingLocation)}
              listings={listingsInRegion}
            />
          </div>
          <SectionAdditionalInfo
            className={css.additionalInfoMobile}
            cancellationPolicy={cancellationPolicy}
          />
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && this.state.enquiryModalOpen}
            onClose={() => this.setState({ enquiryModalOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              authorDisplayName={authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={this.onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: LINE_ITEM_UNITS,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  sendEnquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    reviewsMeta,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    listingsInRegion,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const { sessions, fetchSessionsInProgress, fetchSessionsError } = state.Bookings;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    listingsInRegion,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    reviewsMeta,
    fetchReviewsError,
    sessions,
    fetchSessionsInProgress,
    fetchSessionsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
